import React from "react";
import "./reuse.css";

const Input = (props) => {
  let style = {
    width: "100%",
  };
  let spanstyle = { display: "inline-block", margin: "-4px 10px 0px 10px" };

  if (props.style) {
    style = { ...props.style };
  }

  // if (!style.width) {
  //   style["width"] = "150px";
  //   spanstyle["width"] = "150px";
  // }
  if (!style.height) {
    style["height"] = "40px";
  }
  const { type, placeholder, value, onChange, onKeyDown, max } = props;

  // if(!type |!placeholder){
  //     alert("Some paramenter are missing from input box")
  // }
  return (
    <div className="inputParentHeight" style={props.divStyle}>
      <div className="inputPostion">
        {value && <div className="inputLabelHeight">{placeholder}</div>}
        <input
          style={props.style}
          type={type}
          placeholder={placeholder}
          value={value}
          onKeyDown={onKeyDown || null}
          onChange={onChange}
          className="commonInput"
          maxLength={props.maxLength || ""}
          max={max || ""}
        ></input>
      </div>
    </div>
  );
};

export default Input;
