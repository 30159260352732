import React from "react";
import { getDate } from "../../../Validation/validation";
import HOC from "../../HOC/HOC";
import Button from "../../../resuseComponent/Button";
import { showNotificationMsz } from "../../../Comman/commanUrl";

const ShopProductShare = (props) => {
  const { product } = props.location.state;
  const copyCodeToClipboard = () => {
    var r = document.createRange();

    r.selectNode(document.getElementById("myInput"));
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(r);
    document.execCommand("copy");
    window.getSelection().removeAllRanges();
    showNotificationMsz("Link Copied", "success");
  };
  return (
    <div style={{ padding: 10 }}>
      <div>
        <Button
          text="Copy"
          btnType="small"
          onClick={() => {
            copyCodeToClipboard();
          }}
        />
      </div>
      <div id="myInput">
        <div>
          <b>*रेट लिस्ट-*</b>
          <br />
          <b>
            *Date-{getDate(Date.now()).date}/{getDate(Date.now()).month}/
            {getDate(Date.now()).year}-*
          </b>
          <br />
          <b>*{product[0]?.data?.CategoryName}-*</b>
        </div>
        <div>
          {product.map((row) => (
            <div>
              <div style={{ display: "flex" }}>
                <div style={{ fontSize: 12, paddingLeft: 5 }}>
                  👉🏻 {row.data?.ProductName}- ₹
                  {row?.data?.SalePrice
                    ? row.data?.SalePrice
                    : row.data?.RegularPrice}
                  /- ({row.data?.Weight} {row.data?.WeightUnit})
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HOC(ShopProductShare);
