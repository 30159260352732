// import { url } from "./configuration";

import { Store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { apihandler } from "../api/api";

// const
export const CategoryCONST = "category";
export const ProductCONST = "product";

export const categoryURL = "/product/private/v1/productCategory";
export const productURL = "/product/private/v1/product";
export const productURlWithCategosryID =
  "/product/private/v1/productbycategory?id=";
export const addCategory = "/product/private/v1/productCategory";
export const addProduct = "/product/private/v1/product";
export const editProduct = "/product/private/v1/product";
export const loginURL = "/admin/v1/login";
export const logout = "/admin/v1/logout";
export const orderURl = "/order/private/v1/orders?page=1&count=50";
export const allOrdersURL = "/order/private/v1/orders";
export const uploadCategoryImage = "/product/private/v1/upload/category";
export const abondendcartsURL = "/order/private/v1/abondent";
export const pushNotificationURL = "/admin/v1/notification";
export const getgalleryURL = "/admin/v1/gallery?";
export const uploadgalleryURL = "/admin/v1/upload";
export const loginSessionURL = "/admin/v1/loginsession";
export const updateOrderURL = "/order/private/v1/orderstatus";
export const getCustomerlistURL = "/user/private/v1/getCustomerlist?";
export const getAdminDashboardURl = "/order/private/v1/dashboard?";
export const updatesearchproductURl = "/product/private/v1/updatesearchproduct";
export const getReferallistURL = "/user/private/v1/referallist?page=";
export const getCustomerdetailURL = "/user/private/v1/Customerdetail?custId=";
export const getdeliveryboyURL = "/delboy/private/v1/deliveryboy";
export const assingedelboyURL = "/delboy/private/v1/assingedelboy";
export const getOrderidURL = "/order/private/v1/order?orderid=";
export const delBoyOrderURL = "/delboy/private/v1/orders";
export const liveLocationURL = "/delboy/private/v1/live";
export const getsubscriptionsURL = "/order/private/v1/subscriptions?page=";
export const getproductByIdURL = "/product/private/v1/productById?Id=";
export const subordersURL = "/order/private/v1/suborders?start=";
export const userOrdersURL = "/order/private/v1/userorders?userid=";
export const getAllwalletURL = "/user/private/v1/allwallet?";
export const updateSubscriptionsURL = "/order/private/v1/subscriptions";
export const stockURL = "/product/private/v1/stock";
export const shipaddressURL = "/user/private/v1/shipaddress";
export const offlineOrderURL = "/order/private/v1/offlineOrder";
export const dailyTallyURL = "/delboy/private/v1/dailyTally";
export const searchProductURL = "/product/private/v1/search/product?q=";
export const distributerURL = "/admin/v1/distributer";
export const onlinepaymentURL = "/order/private/v1/onlinepayment";
export const substatusURL = "/order/private/v1/substatus";
export const activeOffersURL = "/user/private/v1/activeOffers";
export const customerNoticeURL = "/user/private/v1/customerNotice";
export const customerURL = "/user/private/v1/customer";
export const shippingcordinateURL = "/user/private/v1/shippingcordinate";
export const shippingaddressURL = "/user/private/v1/shippingaddress";
export const customerSearchURL = "/user/private/v1/customer";
export const searchShippingAddressURL =
  "/user/private/v1/searchShippingAddress";
export const payofflineOrderURL = "/order/private/v1/payofflineOrder";
export const dailyAttendanceURL = "/delboy/private/v1/dailyAttendance";
export const mainwalletURL = "/user/private/v2/mainwallet";
export const serviceAreasURL = "/user/private/v1/serviceAreas";
export const customerDemandURL = "/product/private/v1/customerDemand";
export const enablecodURL = "/user/private/v1/enablecod";
// whole sale routes
export const wsproductCategoryURL = "/product/private/v1/ws-productCategory";
export const wsproductURL = "/product/private/v1/ws-product";
export const wsproductURlWithCategosryIDURL =
  "/product/private/v1/ws-productbycategory?id=";
export const wsgetproductByIdURL = "/product/private/v1/ws-productById?Id=";
export const wsstockURL = "/product/private/v1/ws-stock";
export const shippingCordinateShopURL = "/user/private/v1/shipping_cordinate";
export const wsupdatesearchproductURl =
  "/product/private/v1/ws-updatesearchproduct";
export const wsuserordersURL = "/order/private/v1/ws-userorders?userid=";
export const allwsordersURL = "/order/private/v1/ws-orders";
export const wsorderURL = "/order/private/v1/ws-order";
export const wsupdateOrderURL = "/order/private/v1/ws-orderstatus";
export const wsassingedelboyURL = "/delboy/private/v1/ws-assingedelboy";
export const wspaymentURL = "/order/private/v1/ws-payment";
export const wsgetAdminDashboardURl = "/order/private/v1/ws-dashboard?";
export const wsPaymentStatusURl = "/order/private/v1/ws-paymentStatus";
export const wsPaymentURl = "/order/private/v1/ws-payment";
export const wsPendingPaymentURl = "/order/private/v1/ws-pending-payment";
export const wsServiceAreasURL = "/user/private/v1/ws-serviceAreas";
export const toggleAreadExpressDeliveryURL =
  "/user/private/v1/toggleAreadExpressDelivery";
export const WsshippingaddressURL = "/user/private/v1/ws-shippingaddress";
export const wsCriticalPaymentURl = "/order/private/v1/ws-criticalPayment";
export const wsBeatPlanURl = "/user/private/v1/ws-beatPlan";
export const wsAssigneBeadURl = "/user/private/v1/ws-assigneBead";
//  whole sale routes end
export const PublishedStatus = [
  {
    value: "draft",
    label: "Draft",
  },
  {
    value: "live",
    label: "Live",
  },
  {
    value: "pending review",
    label: "Pending Review",
  },
];

export const onSale = [
  {
    value: true,
    label: "TRUE",
  },
  {
    value: false,
    label: "FALSE",
  },
];

export const StockStaus = [
  {
    value: "In stock",
    label: "In stock",
  },
  {
    value: "Out of Stock",
    label: "Out of Stock",
  },
  {
    value: "On Backorder",
    label: "On Backorder",
  },
  {
    value: "Sold out",
    label: "Sold out",
  },
];
export const googleMapKey = "AIzaSyBcePlYtUQsEC2O33oRmn4uNa-fk8rNFEg";
// show notification msz
export const showNotificationMsz = (message, messageType, time) => {
  Store.addNotification({
    title: "",
    message: `${message}`,
    type: `${messageType}`,
    insert: "top-center",
    container: "top-center",
    dismiss: {
      duration: time || 3000,
      onScreen: true,
    },
  });
};
