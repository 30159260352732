import { combineReducers } from "redux";
var session = document.cookie
  .split("; ")
  .find((row) => row.startsWith("session="))
  ?.split("=")[1];
let intial = {
  islogin: false,
};

const postsReducer = (state = intial, action) => {
  let { type, value } = action;
  switch (action.type) {
    case "LOGIN":
      return { ...state, islogin: action.value };

    default:
      state[type] = value;
      return { ...state };
  }
};

const rootReducer = combineReducers({
  posts: postsReducer,
});

export default postsReducer;
