import React from "react";
import PropTypes from "prop-types";
import "./reuse.css"

const Button = (props) => {
  return (
    <button
      className={
        props.btnType === "small"
          ? "btn btn-common smallButton"
          : "btn btn-common "
      }
      style={props.style}
      onClick={() => {
        props.onClick();
      }}
    >
      {props.text}
    </button>
  );
};

Button.propTypes = {};

export default Button;
