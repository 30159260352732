export const getDate = (timestamp) => {
  let parseValue = new Date(parseInt(timestamp || Date.now())).toString();
  let newTime = parseValue.substring(16, 21);
  let returnDate = {
    year: parseValue.substring(11, 15),
    date: parseValue.substring(8, 10),
    month: parseValue.substring(4, 7),
    time:
      newTime.split(":")[0] === "12"
        ? parseInt(newTime.split(":")[0]) +
          ":" +
          newTime.split(":")[1] +
          " " +
          (parseInt(parseInt(newTime.split(":")[0]) / 12) != 1 ? "AM" : "PM")
        : (parseInt(newTime.split(":")[0]) % 12) +
          ":" +
          newTime.split(":")[1] +
          " " +
          (parseInt(parseInt(newTime.split(":")[0]) / 12) != 1 ? "AM" : "PM"),
  };
  return returnDate;
};
export const blankValidator = (validators, key) => {
  if (
    validators === undefined ||
    validators === "" ||
    validators === null ||
    validators == {} ||
    validators == -1 ||
    validators == NaN
  ) {
    return false;
  }
  return true;
};
export const getDiscountPrice = (checkOutData) => {
  let totalAmount = checkOutData?.data?.reduce((total, item) => {
    return total + +item.SalePrice * item.qty;
  }, 0);
  return Math.floor(+totalAmount) > +checkOutData.Total_Payable_amount
    ? +totalAmount
    : 0;
};
export const calculateDiscountedPercentage = (data) => {
  if (data?.discounted_price == undefined) {
    return 0;
  } else {
    let priceDef = +data.SalePrice - +data.discounted_price;
    return ((priceDef * 100) / +data.SalePrice)?.toFixed(0);
  }
};
