const Style = {
  maincontainer: {
    backgroundColor: "green",
    height: "100%",
  },
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },

  orderTable: {
    padding: "15px",
    border: " 1px solid #d1d1d1",
    borderRadius: "15px",
  },

  orderHeading: {
    backgroundcolor: "#e0e0e0 ",
  },

  phoneNumberView: {
    width: "80%",
    height: 70,
    backgroundColor: "white",
    borderRadius: 15,
  },
  button: {
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 15,
    marginTop: 25,
    width: "80%",
    padding: 8,
    backgroundColor: "#00B8D4",
  },

  buttonText: {
    fontSize: 30,
    textAlign: "center",
    color: "white",
  },
  cardView: {
    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
    transition: " 0.3s",
    borderRadius: "5px",
  },
  dialog: {
    position: "fixed",
    top: "80px",
    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
    transition: " 0.3s",
    borderRadius: "5px",
    width: "60%",
    border: "1px dotted black",
    zIndex: "3",
    height: "75vh",
  },
  dispalyFlex: {
    display: "flex",
  },
  floatRight: {
    marginLeft: "auto",
    marginRight: "14px",
  },
  commonSpace: {
    padding: "0px 10px",
  },
  dailogAction: {
    textAlign: "end",
    height: "40px",
  },
};

export default Style;
