import React, { Suspense } from "react";
import Loading from "../Loading/Loading";
import { Routes, Route } from "react-router-dom";
import NoPage from "../NotFoundPage/NotFoundPage";
import ShopProductShare from "../ShopMgmt/ShopProductCategory/ShopProductShare";

// lazy loading=-=-=-=-=-=-=-
const DashBoard = React.lazy(() => import("../DashBoard/DashBoard"));
const Orders = React.lazy(() => import("../Orders/Orders"));
const ManageProduct = React.lazy(() => import("../Product/ManageProduct"));
const ProductDetail = React.lazy(() => import("../Product/ProductDetail"));
const ManageCategory = React.lazy(() => import("../Category/ManageCategory"));
const Printinvoice = React.lazy(() => import("../Orders/Printinvoice"));
const AbondentCart = React.lazy(() => import("../AbdCart/abondentCart"));
const OrderDetails = React.lazy(() => import("../Orders/OrderDetails"));
const Customer = React.lazy(() => import("../Customer/Customer"));
const Gallery = React.lazy(() => import("../Gallery/Gallery"));
const CustomerDetails = React.lazy(() => import("../Customer/CustomerDetails"));
const DeliveryPartner = React.lazy(() =>
  import("../DeliveryPartner/DeliveryPartner")
);
const PushNotification = React.lazy(() =>
  import("../PushNotification/PushNotification")
);
const ReferalDetails = React.lazy(() =>
  import("../ReferalDetails/ReferalDetails")
);
const SubscriptionPage = React.lazy(() =>
  import("../Subscription/SubscriptionPage")
);
const SubscriptionOrder = React.lazy(() =>
  import("../Subscription/SubscriptionOrder")
);
const SubscriptionPrintInvoice = React.lazy(() =>
  import("../Subscription/SubscriptionPrintInvoice")
);
const WalletData = React.lazy(() => import("../WalletData/WalletData"));
const CustomerShippingAddress = React.lazy(() =>
  import("../Customer/CustomerShippingAddress")
);
const OffLinePlaceOrder = React.lazy(() =>
  import("../Orders/OffLinePlaceOrder")
);
const OffLineOrderList = React.lazy(() => import("../Orders/OffLineOrderList"));
const DistributerList = React.lazy(() =>
  import("../DistributerList/DistributerList")
);
const DailyInventory = React.lazy(() =>
  import("../DailyInventory/DailyInventory")
);
const CustomerActiveOffers = React.lazy(() =>
  import("../CustomerActiveOffers/CustomerActiveOffers")
);
const CustomerNotice = React.lazy(() =>
  import("../CustomerActiveOffers/CustomerNotice")
);
const ShopsList = React.lazy(() => import("../ShopMgmt/ShopList/ShopsList"));
const ShopOrderPlace = React.lazy(() =>
  import("../ShopMgmt/ShopOrders/ShopOrderPlace")
);
const ShopOrderList = React.lazy(() =>
  import("../ShopMgmt/ShopOrders/ShopOrderList")
);
const ShopCategory = React.lazy(() =>
  import("../ShopMgmt/ShopProductCategory/ShopCategory")
);
const ShopProductList = React.lazy(() =>
  import("../ShopMgmt/ShopProductCategory/ShopProductList")
);
const PendingPayMent = React.lazy(() =>
  import("../ShopMgmt/ShopPayment/PendingPayMent")
);
const ShopProductDetail = React.lazy(() =>
  import("../ShopMgmt/ShopProductCategory/ShopProductDetail")
);
const CreateShopOrder = React.lazy(() =>
  import("../ShopMgmt/ShopOrders/CreateShopOrder")
);
const ShopDashBoard = React.lazy(() =>
  import("../ShopMgmt/ShopDashBoard/ShopDashBoard")
);
const ShopOrderDetails = React.lazy(() =>
  import("../ShopMgmt/ShopOrders/ShopOrderDetails")
);
const ShopPrintInvoice = React.lazy(() =>
  import("../ShopMgmt/ShopOrders/ShopPrintInvoice")
);
const ShopOrderListByDate = React.lazy(() =>
  import("../ShopMgmt/ShopOrders/ShopOrderListByDate")
);
const UpdateShopOrder = React.lazy(() =>
  import("../ShopMgmt/ShopOrders/UpdateShopOrder")
);
const ServicesArea = React.lazy(() => import("../Customer/ServicesArea"));
const ShopServiceArea = React.lazy(() =>
  import("../ShopMgmt/ShopServicesArea/ShopServiceArea")
);
const CustomerDemand = React.lazy(() =>
  import("../CustomerDemand/CustomerDemand")
);
const BeatPlanList = React.lazy(() =>
  import("../ShopMgmt/BeatPlan/BeatPlanList")
);
const ShopShipppingAddress = React.lazy(() =>
  import("../ShopMgmt/ShopShipppingAddress/ShopShipppingAddress")
);
const PrintSticker = React.lazy(() =>
  import("../ShopMgmt/PrintSticker/PrintSticker")
);

const Router = (props) => {
  return (
    <div>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/" element={<DashBoard {...props} />} />
          <Route
            path="/placeOrder/:id"
            element={<OffLinePlaceOrder {...props} />}
          />
          <Route path="/orders" element={<Orders {...props} />}></Route>
          <Route path="/product" element={<ManageProduct {...props} />}></Route>
          <Route
            path="/productDetail"
            element={<ProductDetail {...props} />}
          ></Route>
          <Route
            path="/allproduct"
            element={<ManageProduct {...props} />}
          ></Route>
          <Route
            path="/category"
            element={<ManageCategory {...props} />}
          ></Route>
          <Route
            path="/Printinvoice"
            element={<Printinvoice {...props} />}
          ></Route>
          <Route
            path="/abondentCart"
            element={<AbondentCart {...props} />}
          ></Route>

          <Route
            path="/OrderDetails"
            element={<OrderDetails {...props} />}
          ></Route>
          <Route
            path="/PushNotfication"
            element={<PushNotification {...props} />}
          ></Route>
          <Route path="Gallery" element={<Gallery {...props} />}></Route>
          <Route path="Customer" element={<Customer {...props} />}></Route>
          <Route
            path="ReferalDetails"
            element={<ReferalDetails {...props} />}
          ></Route>
          <Route
            path="CustomerDetails/:customId/"
            element={<CustomerDetails {...props} />}
          ></Route>
          <Route
            path="DeliveryPartner"
            element={<DeliveryPartner {...props} />}
          ></Route>
          <Route
            path="Subscription"
            element={<SubscriptionPage {...props} />}
          ></Route>
          <Route
            path="Subscription-order"
            element={<SubscriptionOrder {...props} />}
          ></Route>
          <Route
            path="SubPrintInvoice"
            element={<SubscriptionPrintInvoice {...props} />}
          ></Route>
          <Route path="/allWallet" element={<WalletData {...props} />}></Route>
          <Route
            path="/customer-shipping-address"
            element={<CustomerShippingAddress {...props} />}
          ></Route>
          <Route
            path="/Offline-order"
            element={<OffLineOrderList {...props} />}
          ></Route>
          <Route
            path="/daily-inventory"
            element={<DailyInventory {...props} />}
          ></Route>
          <Route
            path="/distributer-list"
            element={<DistributerList {...props} />}
          ></Route>
          <Route
            path="/customer-active-offers"
            element={<CustomerActiveOffers {...props} />}
          ></Route>
          <Route
            path="/customer-notice"
            element={<CustomerNotice {...props} />}
          ></Route>
          <Route path="/shop-list" element={<ShopsList {...props} />}></Route>
          <Route
            path="/shop-order-place"
            element={<ShopOrderPlace {...props} />}
          ></Route>
          <Route
            path="/shop-order-List"
            element={<ShopOrderList {...props} />}
          ></Route>
          <Route
            path="/shop-category"
            element={<ShopCategory {...props} />}
          ></Route>
          <Route
            path="/shop-product"
            element={<ShopProductList {...props} />}
          ></Route>
          <Route
            path="/shop-product-detail"
            element={<ShopProductDetail {...props} />}
          ></Route>
          <Route
            path="/pending-payment"
            element={<PendingPayMent {...props} />}
          ></Route>
          <Route
            path="/createShopOrder"
            element={<CreateShopOrder {...props} />}
          ></Route>
          <Route
            path="ShopDashBoard"
            element={<ShopDashBoard {...props} />}
          ></Route>
          <Route
            path="shop-order-detail"
            element={<ShopOrderDetails {...props} />}
          ></Route>
          <Route
            path="shop-Printinvoice"
            element={<ShopPrintInvoice {...props} />}
          ></Route>
          <Route
            path="shop-product-share"
            element={<ShopProductShare {...props} />}
          ></Route>
          <Route
            path="/shop-order-List-by-date"
            element={<ShopOrderListByDate {...props} />}
          ></Route>
          <Route
            path="/updateShopOrder"
            element={<UpdateShopOrder {...props} />}
          ></Route>
          <Route
            path="/servicesArea"
            element={<ServicesArea {...props} />}
          ></Route>
          <Route
            path="/ShopServiceArea"
            element={<ShopServiceArea {...props} />}
          ></Route>
          <Route
            path="/CustomerDemand"
            element={<CustomerDemand {...props} />}
          ></Route>
          <Route
            path="/BeatPlanList"
            element={<BeatPlanList {...props} />}
          ></Route>
          <Route
            path="/Shop-shipping-Address"
            element={<ShopShipppingAddress {...props} />}
          ></Route>
          <Route
            path="/Shop-print-sticker"
            element={<PrintSticker {...props} />}
          ></Route>

          {/* ---------no url found page */}
          <Route element={<NoPage {...props} />}></Route>
        </Routes>
      </Suspense>
    </div>
  );
};

Router.propTypes = {};

export default Router;
