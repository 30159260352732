import React, { useState, useEffect } from "react";
import "./HOC.css";
import AppBar from "@mui/material/AppBar";
import { Toolbar } from "@mui/material";
import { getDate } from "../../Validation/validation";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import Button from "../../resuseComponent/Button";
import { globalDate } from "../../Comman/commonConst";
import { apihandler } from "../../api/api";
import {
  subordersURL,
  wsCriticalPaymentURl,
  wsgetAdminDashboardURl,
} from "../../Comman/commanUrl";
import { useDispatch } from "react-redux";

const Header = (props) => {
  const dispatch = useDispatch();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [calenderDailog, setCalenderDailog] = useState(false);
  const [subscriptionOrder, setSubscriptionOrder] = useState([]);
  const [orderList, setOrderList] = useState([]);

  let YesterdayStartDate = new Date(
    new Date().setDate(new Date().getDate() - 1)
  ).setHours(0, 0, 0, 0);
  let YesterdayEndDate = new Date(
    new Date().setDate(new Date().getDate() - 1)
  ).setHours(23, 59, 59, 999);

  useEffect(() => {
    if (localStorage.getItem("StartDate")) {
      setStartDate(localStorage.getItem("StartDate"));
      setEndDate(localStorage.getItem("EndDate"));
      let data = {
        startDate: localStorage.getItem("StartDate"),
        endDate: localStorage.getItem("EndDate"),
      };
      dispatch({
        type: globalDate,
        value: data,
      });
    } else {
      let data = {
        startDate: new Date(new Date().setHours(0, 0, 0, 0)).getTime(),
        endDate: new Date(new Date().setHours(23, 59, 59, 999)).getTime(),
      };
      dispatch({
        type: globalDate,
        value: data,
      });
      setStartDate(new Date(new Date().setHours(0, 0, 0, 0)).getTime());
      setEndDate(new Date(new Date().setHours(23, 59, 59, 999)).getTime());
    }
  }, []);
  useEffect(() => {
    getAllSubscriptionOrder();
    GetPendingOrderpayment();
  }, []);
  const getAllSubscriptionOrder = async () => {
    let todayStartDate = new Date().setHours(0, 0, 0, 0);
    let todayEndDate = new Date().setHours(23, 59, 59, 999);
    let { data, status } = await apihandler(
      {},
      `${subordersURL}${todayStartDate}&end=${todayEndDate}`,
      "get"
    );
    if (status === 1) {
      let filerData = data?.filter((event, index) => {
        return event != ""
          ? event.order_status
              ?.toLowerCase()
              ?.indexOf("Order Placed".toLowerCase()) !== -1
          : true;
      });
      setSubscriptionOrder(filerData);
    }
  };
  const getDataForSlection = (startDate, endDate) => {
    try {
      localStorage.setItem("StartDate", startDate);
      localStorage.setItem("EndDate", endDate);
      let data = {
        startDate: startDate,
        endDate: endDate,
      };
      dispatch({
        type: "GLOBALDATE",
        value: data,
      });
      setStartDate(startDate);
      setEndDate(endDate);
      setCalenderDailog(false);
      // getOrderSates(startDate, endDate);
    } catch (error) {
      alert("Error in something , Please ask admin to resolve issue ");
    }
  };
  const GetPendingOrderpayment = async () => {
    // let startDate = new Date(
    //   new Date().setFullYear(new Date().getFullYear() - 1)
    // ).getTime();
    // let endDate = new Date().setMonth(new Date().getMonth() - 1);

    let { status, message, data } = await apihandler(
      {},
      wsCriticalPaymentURl,
      "get"
    );
    if (status === 1) {
      setOrderList(data);
    }
  };
  return (
    <div>
      <AppBar position="fixed" className="header_desktop_view">
        <Toolbar className="normal_header_padding">
          <h2
            onClick={() => {
              props.openSideBarhandle();
            }}
            style={{ color: "black", cursor: "pointer" }}
            className="sidenav-trigger show-on-large "
          >
            <i className="material-icons">menu</i>
          </h2>
          <div>
            <img src="/assets/logo.png" alt="logo" width="130" />
          </div>
          <div style={{ paddingLeft: 10 }}>
            <div
              style={{
                backgroundColor: "yellow",
                color: "black",
                fontWeight: "bold",
                padding: "2px 5px",
                cursor: "pointer",
              }}
              onClick={() => {
                props.navigate("/Subscription-order");
              }}
            >
              &rarr; {subscriptionOrder?.length} Subscription orders{" "}
            </div>
            <div>
              <a
                href={`/shop-order-List-by-date?OrderStatus=Delivered&paymentStatus=Pending Payment`}
                target="_blank"
                style={{
                  backgroundColor: "red",
                  color: "white",
                  fontWeight: "bold",
                  padding: "2px 5px",
                  cursor: "pointer",
                  textDecoration: "none",
                  outline: "none",
                }}
              >
                &rarr; Critical Pending Payment Of Rs. {orderList}
              </a>
            </div>
          </div>
          <div className="candidate_header_grow" />
          <div
            className="dashboardCalenderflex buttonPadding"
            onClick={() => {
              setCalenderDailog(true);
            }}
          >
            |{" "}
            {`${getDate(startDate).date}/${getDate(startDate).month}/${
              getDate(startDate).year
            }`}{" "}
            -{" "}
            {`${getDate(endDate).date}/${getDate(endDate).month}/${
              getDate(endDate).year
            }`}{" "}
            |
          </div>
          <div className="ProfileName">
            <h4>{props?.islogin?.Name}</h4>
          </div>
        </Toolbar>

        {subscriptionOrder?.length > 0 ? (
          <div className="subscripionHeaderBgColor">
            <b>
              {subscriptionOrder?.length} Subscription orders अभी भी डिलीवरी के
              लिए पड़े हुए हैं /{subscriptionOrder?.length} Subscription orders
              are still pending for delivery{" "}
            </b>
          </div>
        ) : (
          ""
        )}
      </AppBar>
      <Dialog
        open={calenderDailog}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle style={{ textAlign: "center" }}>
          Dashboard analytics
          <span
            className="dashboardCloseIcon"
            onClick={() => {
              setCalenderDailog(false);
            }}
          >
            X
          </span>
        </DialogTitle>
        <DialogContent>
          <div>
            <Grid container spacing={3}>
              <Grid item md={4}>
                <div className="calenderLeftGrid">
                  <p
                    onClick={() => {
                      getDataForSlection(
                        new Date(new Date().setHours(0, 0, 0, 0)).getTime(),
                        new Date(new Date().setHours(23, 59, 59, 999)).getTime()
                      );
                    }}
                  >
                    Today
                  </p>
                  <p
                    onClick={() => {
                      getDataForSlection(YesterdayStartDate, YesterdayEndDate);
                    }}
                  >
                    Yesterday
                  </p>
                  <p
                    onClick={() => {
                      getDataForSlection(
                        new Date(
                          new Date().setDate(new Date().getDate() - 1)
                        ).setHours(0, 0, 0, 0),
                        new Date(
                          new Date().setDate(new Date().getDate())
                        ).setHours(23, 59, 59, 999)
                      );
                    }}
                  >
                    Last Two Days
                  </p>
                  <p
                    onClick={() => {
                      var date = new Date();
                      getDataForSlection(
                        new Date(
                          new Date(date.getFullYear(), date.getMonth(), 1)
                        ).setHours(0, 0, 0, 0),
                        new Date(
                          new Date(date.getFullYear(), date.getMonth() + 1, 0)
                        ).setHours(23, 59, 59, 999)
                      );
                    }}
                  >
                    This Month
                  </p>
                  <p
                    onClick={() => {
                      var date = new Date();
                      getDataForSlection(
                        new Date(
                          new Date(date.getFullYear(), date.getMonth() - 1, 1)
                        ).setHours(0, 0, 0, 0),
                        new Date(
                          new Date(date.getFullYear(), date.getMonth(), 0)
                        ).setHours(23, 59, 59, 999)
                      );
                    }}
                  >
                    Last Month
                  </p>

                  <p
                    onClick={() => {
                      var date = new Date();
                      getDataForSlection(
                        new Date(new Date(date.getFullYear(), 0, 1)).setHours(
                          0,
                          0,
                          0,
                          0
                        ),
                        new Date(
                          new Date(date.getFullYear() + 1, 0, 0)
                        ).setHours(23, 59, 59, 999)
                      );
                    }}
                  >
                    This Year
                  </p>
                  <p
                    onClick={() => {
                      var date = new Date();
                      getDataForSlection(
                        new Date(
                          new Date(date.getFullYear() - 1, 0, 1)
                        ).setHours(0, 0, 0, 0),
                        new Date(new Date(date.getFullYear(), 0, 0)).setHours(
                          23,
                          59,
                          59,
                          999
                        )
                      );
                    }}
                  >
                    Last Year
                  </p>
                </div>
              </Grid>
              <Grid item md={8}>
                <div>
                  <div className="calenderDesign">
                    <p>Custom</p>
                    <span>Start Date: </span>
                    <input
                      type="date"
                      onChange={(e) => {
                        setStartDate(
                          new Date(e.target.value).setHours(0, 0, 0, 0)
                        );
                      }}
                    />
                  </div>
                  <div className="calenderDesign">
                    <span>End Date: &nbsp;</span>
                    <input
                      type="date"
                      onChange={(e) => {
                        setEndDate(
                          new Date(e.target.value).setHours(23, 59, 59, 999)
                        );
                      }}
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            btnType="small"
            style={{ padding: "5px 20px" }}
            onClick={() => {
              setCalenderDailog(false);
              getDataForSlection(startDate, endDate);
            }}
            text="Apply"
          ></Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Header;
