import React, { useState } from "react";
import { apihandler } from "../../api/api";
import {
  categoryURL,
  loginURL,
  showNotificationMsz,
} from "../../Comman/commanUrl";
import Button from "../../resuseComponent/Button";
import Input from "../../resuseComponent/Input";
import Loader from "../../resuseComponent/Loader";

import "./Login.css";

export default function Login(props) {
  const [email, setEmail] = useState("");
  const [Password, setPassword] = useState("");

  const [loginIdError, setLoginIdError] = useState(false);
  const [PasswordError, setPasswordError] = useState(false);
  const [passwordShow, setPasswordShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const something = (event) => {
    if (event.keyCode === 13) {
      loginHandle();
    }
  };

  const loginHandle = async () => {
    if (email === "") {
      setLoginIdError(true);
    }
    if (Password === "") {
      setPasswordError(true);
    }
    if (email === "" || Password === "") {
      return;
    }
    setIsLoading(true);
    let body = {
      username: email,
      password: Password,
    };
    let { data, status, message } = await apihandler(body, loginURL, "post");
    showNotificationMsz(message, "success");
    if (status == 1) {
      props.dispatch({
        type: "LOGIN",
        value: data,
      });
    }
    setIsLoading(false);
  };

  return (
    <div className="LoginmainDiv">
      <div className="Internal_div">
        <h2 className="loginTitleCenter">Login</h2>
        <div>
          <div>
            <Input
              type="text"
              placeholder="User Name"
              onKeyDown={(e) => something(e)}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setLoginIdError(false);
              }}
            ></Input>
            {loginIdError && <p className="validcolor">Enter the Password</p>}
          </div>
          <div>
            <div className="d-relative">
              <Input
                type={passwordShow ? "text" : "password"}
                onKeyDown={(e) => something(e)}
                placeholder="Password"
                value={Password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setPasswordError(false);
                }}
              ></Input>
              {passwordShow ? (
                <i
                  className="material-icons seteyeicon"
                  onClick={() => {
                    setPasswordShow(false);
                  }}
                >
                  visibility
                </i>
              ) : (
                <i
                  className="material-icons seteyeicon"
                  onClick={() => {
                    setPasswordShow(true);
                  }}
                >
                  visibility_off
                </i>
              )}
              {PasswordError && (
                <p className="validcolor">Enter the Password</p>
              )}
            </div>
          </div>
          <Button
            text="Login"
            onClick={loginHandle}
            style={{ marginTop: 20 }}
          ></Button>
        </div>
      </div>
      <Loader isLoading={isLoading} />
    </div>
  );
}
