import React, { Component, Fragment } from "react";
import Header from "./Header";
import "./HOC.css";
import SideBar from "./SideBar";

const HOC = (WrappedComponent) => {
  return class extends Component {
    constructor(props) {
      super(props);

      this.state = {
        sidebarOpen: true,
      };
    }
    openSideBarhandle = () => {
      this.setState({
        sidebarOpen: !this.state.sidebarOpen,
      });
    };
    render() {
      return (
        <Fragment>
          <div>
            <Header
              {...this.props}
              openSideBarhandle={this.openSideBarhandle}
            ></Header>
            <div style={{ height: 70 }}></div>
            <SideBar open={this.state.sidebarOpen} {...this.props} />
            <div style={{ width: "calc ( 100% - 80px )", paddingLeft: 85 }}>
              <WrappedComponent {...this.props} />
            </div>
          </div>
        </Fragment>
      );
    }
  };
};

export default HOC;
