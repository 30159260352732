import "./App.css";
import { connect } from "react-redux";
import Login from "./components/Login/Login";
import AfterLogin from "./components/AfterLogin/AfterLogin";
import { useEffect, useState } from "react";
import { allOrdersURL, loginSessionURL } from "./Comman/commanUrl";
import { apihandler } from "./api/api";
import Loader from "./resuseComponent/Loader";
import new_order from "./new_order.mp3";
import { ReactNotifications } from "react-notifications-component";

function App(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [refreshInterval, setRefreshInterval] = useState(10000);

  useEffect(() => {
    getLoginSession();
  }, []);
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     getOrder();
  //   }, refreshInterval);
  //   return () => {
  //     clearInterval(interval);
  //   };
  // });

  const getLoginSession = async () => {
    let { status, message, data } = await apihandler(
      {},
      loginSessionURL,
      "get"
    );
    if (status === 1) {
      props.dispatch({
        type: "LOGIN",
        value: data,
      });
    }
    setIsLoading(false);
  };
  // const getOrder = async () => {
  //   let url = allOrdersURL + `?page=${1}&count=2`;
  //   let { status, message, data } = await apihandler({}, url, "get");
  //   if (status === 1) {
  //     if (data[0].order_timestamp > Date.now() - refreshInterval) {
  //       var audio = new Audio(new_order);
  //       audio.play();
  //     }
  //   }
  // };

  if (isLoading) {
    return <Loader isLoading={isLoading} />;
  }
  return (
    <div>
      <ReactNotifications />
      {props.islogin ? (
        <AfterLogin {...props}></AfterLogin>
      ) : (
        <Login {...props}></Login>
      )}
    </div>
  );
}

const mapStateToProps = (state, ownProps) => ({
  islogin: state.islogin || false,
});
export default connect(mapStateToProps)(App);
