import React from 'react';
import PropTypes from 'prop-types';

const NoPage = props => {
    return (
        <div>
            this page does nto exist thanks 
        </div>
    );
};

NoPage.propTypes = {
    
};

export default NoPage;