import React, { useState } from "react";

import { useNavigate, useLocation } from "react-router-dom";

import LeftBar from "./LeftBar";
import Navbar from "./Navbar";
import RouterforApp from "./Router";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";

const AfterLogin = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [sidebarOpen, setSidebaropen] = useState(false);

  const openSidebar = () => {
    setSidebaropen(!sidebarOpen);
  };

  return (
    <div>
      <div style={{ display: "flex" }}>
        {/* <LeftBar
          {...props}
          openSidebar={openSidebar}
          width={sidebarOpen ? "block" : "none"}
          navigate={navigate}
          location={location}
        ></LeftBar> */}
        <div style={{ width: "100%" }}>
          {/* <Navbar
            {...props}
            openSidebar={openSidebar}
            sidebarOpen={sidebarOpen}
            navigate={navigate}
            location={location}
          ></Navbar> */}
          <div>
            <RouterforApp
              {...props}
              navigate={navigate}
              location={location}
            ></RouterforApp>
          </div>
        </div>
      </div>
      <div
        className="addOrder"
        onClick={() => {
          window.open(`/placeOrder/${Date.now()}`);
        }}
      >
        <Fab color="secondary" aria-label="add" title="Place Order">
          <AddIcon />
        </Fab>
      </div>
    </div>
  );
};

export default AfterLogin;
