import React from "react";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { apihandler } from "../../api/api";
import { logout, showNotificationMsz } from "../../Comman/commanUrl";

const SideBar = (props) => {
  const logOutHandle = async () => {
    if (window.confirm("Are you sure to LogOut?")) {
      let { status, message } = await apihandler({}, logout, "get");
      showNotificationMsz(message, "success");
      if (status == 1) {
        props.dispatch({
          type: "LOGIN",
          value: false,
        });
        props.navigate("/");
      }
    } else {
      return true;
    }
  };
  return (
    <Sidebar
      className="sidebarMainPostion"
      collapsed={props.open}
      backgroundColor="white"
      style={{ paddingTop: 40 }}
    >
      <Menu
      // menuItemStyles={{
      //   button: ({ level, active, disabled }) => {
      //     // only apply styles on first level elements of the tree
      //     if (level === 0)
      //       return {
      //         color: disabled ? "#f5d9ff" : "#gray",
      //         backgroundColor: active ? "#eecef9" : undefined,
      //       };
      //   },
      // }}
      >
        {" "}
        <MenuItem
          onClick={() => {
            props.navigate("/");
          }}
          icon={<i className={"material-icons LeftIConPadding"}>dashboard</i>}
        >
          {" "}
          DashBoard{" "}
        </MenuItem>
        <SubMenu
          label="Products"
          icon={<i className={"material-icons LeftIConPadding"}>inventory</i>}
        >
          <MenuItem
            onClick={() => {
              props.navigate("/category");
            }}
            icon={<i className={"material-icons LeftIConPadding"}>category</i>}
          >
            Category
          </MenuItem>
          <MenuItem
            onClick={() => {
              props.navigate("/allproduct");
            }}
            icon={<i className={"material-icons LeftIConPadding"}>inventory</i>}
          >
            All Products
          </MenuItem>
        </SubMenu>
        <SubMenu
          // rootStyles={{ top: 100 }}
          label="Orders"
          icon={<i className={"material-icons LeftIConPadding"}>reorder</i>}
        >
          <MenuItem
            onClick={() => {
              props.navigate("/orders");
            }}
            icon={<i className={"material-icons LeftIConPadding"}>edit_note</i>}
          >
            Online Orders
          </MenuItem>
          <MenuItem
            onClick={() => {
              props.navigate("/Subscription-order");
            }}
            icon={<i className={"material-icons LeftIConPadding"}>loyalty</i>}
          >
            Subscription Order
          </MenuItem>
          <MenuItem
            onClick={() => {
              props.navigate("/Offline-order");
            }}
            icon={<i className={"material-icons LeftIConPadding"}>wifi_off</i>}
          >
            Offline Orders
          </MenuItem>
          <MenuItem
            onClick={() => {
              props.navigate("/Subscription");
            }}
            icon={
              <i className={"material-icons LeftIConPadding"}>subscriptions</i>
            }
          >
            All Subscription
          </MenuItem>
          <MenuItem
            onClick={() => {
              props.navigate("/abondentCart");
            }}
            icon={
              <i className={"material-icons LeftIConPadding"}>
                add_shopping_cart
              </i>
            }
          >
            Abandoned cart
          </MenuItem>
          <MenuItem
            onClick={() => {
              props.navigate("/CustomerDemand");
            }}
            icon={
              <i className={"material-icons LeftIConPadding"}>
                add_shopping_cart
              </i>
            }
          >
            Customer Demand
          </MenuItem>
        </SubMenu>
        <SubMenu
          label="Customer"
          icon={<i className={"material-icons LeftIConPadding"}>people</i>}
        >
          <MenuItem
            onClick={() => {
              props.navigate("/Customer");
            }}
            icon={<i className={"material-icons LeftIConPadding"}>people</i>}
          >
            Customer List
          </MenuItem>
          <MenuItem
            onClick={() => {
              props.navigate("/customer-shipping-address");
            }}
            icon={
              <i className={"material-icons LeftIConPadding"}>local_shipping</i>
            }
          >
            Shipping Address
          </MenuItem>
          <MenuItem
            onClick={() => {
              props.navigate("/ReferalDetails");
            }}
            icon={<i className={"material-icons LeftIConPadding"}>share</i>}
          >
            Referal Details
          </MenuItem>

          <MenuItem
            onClick={() => {
              props.navigate("/allWallet");
            }}
            icon={<i className={"material-icons LeftIConPadding"}>wallet</i>}
          >
            Wallet Data
          </MenuItem>
          <MenuItem
            onClick={() => {
              props.navigate("/servicesArea");
            }}
            icon={<i className={"material-icons LeftIConPadding"}>wallet</i>}
          >
            Service Area
          </MenuItem>
        </SubMenu>
        <SubMenu
          label="Shops"
          icon={<i className={"material-icons LeftIConPadding"}>storefront</i>}
        >
          <MenuItem
            onClick={() => {
              props.navigate("/ShopDashBoard");
            }}
            icon={
              <i className={"material-icons LeftIConPadding"}>dashboard </i>
            }
          >
            DashBoard
          </MenuItem>
          <MenuItem
            onClick={() => {
              props.navigate("/shop-list");
            }}
            icon={
              <i className={"material-icons LeftIConPadding"}>
                production_quantity_limits{" "}
              </i>
            }
          >
            All Shops
          </MenuItem>
          <MenuItem
            onClick={() => {
              props.navigate("/shop-order-List");
            }}
            icon={<i className={"material-icons LeftIConPadding"}>redeem </i>}
          >
            Shop Orders
          </MenuItem>
          <SubMenu
            label="Shop Products"
            icon={
              <i className={"material-icons LeftIConPadding"}>storefront</i>
            }
          >
            <MenuItem
              onClick={() => {
                props.navigate("/shop-category");
              }}
              icon={
                <i className={"material-icons LeftIConPadding"}>category </i>
              }
            >
              Category
            </MenuItem>
          </SubMenu>
          <MenuItem
            onClick={() => {
              props.navigate("/pending-payment");
            }}
            icon={<i className={"material-icons LeftIConPadding"}>redeem </i>}
          >
            Pending Payments
          </MenuItem>
          <MenuItem
            onClick={() => {
              props.navigate("/ShopServiceArea");
            }}
            icon={<i className={"material-icons LeftIConPadding"}>redeem </i>}
          >
            Shop Services Area
          </MenuItem>
          <MenuItem
            onClick={() => {
              props.navigate("/BeatPlanList");
            }}
            icon={
              <i className={"material-icons LeftIConPadding"}>
                directions_bike{" "}
              </i>
            }
          >
            Beat Plan
          </MenuItem>
          <MenuItem
            onClick={() => {
              props.navigate("/Shop-shipping-Address?type=shop");
            }}
            icon={
              <i className={"material-icons LeftIConPadding"}>
                directions_bike{" "}
              </i>
            }
          >
            Shop Shipping Address
          </MenuItem>
        </SubMenu>
        <SubMenu
          label="Settings"
          icon={<i className={"material-icons LeftIConPadding"}>settings</i>}
        >
          <MenuItem
            onClick={() => {
              props.navigate("/DeliveryPartner");
            }}
            icon={
              <i className={"material-icons LeftIConPadding"}>
                delivery_dining
              </i>
            }
          >
            Delivery Partner
          </MenuItem>
          <MenuItem
            onClick={() => {
              props.navigate("/PushNotfication");
            }}
            icon={
              <i className={"material-icons LeftIConPadding"}>notifications</i>
            }
          >
            Push Notification
          </MenuItem>
          <MenuItem
            onClick={() => {
              props.navigate("/Gallery");
            }}
            icon={<i className={"material-icons LeftIConPadding"}>image</i>}
          >
            Gallery
          </MenuItem>
          <MenuItem
            onClick={() => {
              props.navigate("/daily-inventory");
            }}
            icon={
              <i className={"material-icons LeftIConPadding"}>fingerprint </i>
            }
          >
            Daily Inventory
          </MenuItem>
          <MenuItem
            onClick={() => {
              props.navigate("/distributer-list");
            }}
            icon={
              <i className={"material-icons LeftIConPadding"}>
                volunteer_activism{" "}
              </i>
            }
          >
            Distributer List
          </MenuItem>
          <MenuItem
            onClick={() => {
              props.navigate("/customer-active-offers");
            }}
            icon={
              <i className={"material-icons LeftIConPadding"}>local_offer </i>
            }
          >
            Cust. Active Offers
          </MenuItem>
          <MenuItem
            onClick={() => {
              props.navigate("/customer-notice");
            }}
            icon={
              <i className={"material-icons LeftIConPadding"}>local_offer </i>
            }
          >
            Customer Notice
          </MenuItem>
          <MenuItem
            onClick={() => {
              logOutHandle();
            }}
            icon={
              <i className={"material-icons LeftIConPadding"}>exit_to_app</i>
            }
          >
            {" "}
            Logout{" "}
          </MenuItem>
        </SubMenu>
      </Menu>
    </Sidebar>
  );
};

export default SideBar;
